export default {
  taskStatusList: {
    0: {
      value: 0,
      label: "扫描中",
      color: "#2db7f5",
    },
    1: {
      value: 1,
      label: "已完成",
      color: "#87d068",
    },
  },
  taskHitList: {
    0: {
      value: 0,
      label: "扫描中",
      color: "#2db7f5",
    },
    1: {
      value: 1,
      label: "命中",
      color: "#87d068",
    },
    2: {
      value: 2,
      label: "未命中",
      color: "#808080",
    },
  },
};
