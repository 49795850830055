export default {
  pluginTypeList: {
    0: "URL",
    1: "HOST",
    2: "DOMAIN",
  },
  pluginStatusList: {
    0: {
      value: 0,
      label: "下线",
      color: "#2db7f5",
    },
    1: {
      value: 1,
      label: "上线",
      color: "#87d068",
    },
  },
};
