import axios from "@/libs/api.request";

export const getTaskList = (query) => {
  return axios.request({
    url: "/task/scan/",
    method: "get",
    params: query,
  });
};

export const getTask = (id) => {
  return axios.request({
    url: "/task/scan/" + id + "/",
    method: "get",
  });
};

export const addTask = (reqdata) => {
  return axios.request({
    url: "/task/scan/create_task/",
    method: "post",
    data: reqdata,
  });
};

export const appendTask = (reqdata) => {
  return axios.request({
    url: "/task/scan/append_task/",
    method: "post",
    data: reqdata,
  });
};

export const changeFormat = (reqdata) => {
  return axios.request({
    url: "/task/scan/url_format/",
    method: "post",
    data: reqdata,
  });
};

export const deleteTask = (id) => {
  return axios.request({
    url: "/task/scan/" + id + "/",
    method: "delete",
  });
};

export const getTaskDetail = (query) => {
  return axios.request({
    url: "/task/detail/",
    method: "get",
    params: query,
  });
};
