<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="create_task">创建任务</a-button>
    <a-button type="primary" style="margin-left: 10px;" icon="plus" @click="change_format">格式转换</a-button>
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px; float: right"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px;"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="type" slot-scope="text">
        {{ text | typePlugin }}
      </span>
      <span slot="create_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="name" slot-scope="text, task">
        <router-link
          :to="{
            path: '/task/detail',
            query: { id: task.id },
          }"
          >{{ text }}</router-link
        >
      </span>
      <span slot="status" slot-scope="text">
        <a-tag :color="text | pluginStatusColor">{{
          text | pluginStatusText
        }}</a-tag>
      </span>
      <span slot="action" slot-scope="text">
        <a-button
          type="danger"
          size="small"
          @click="delete_task(text.id)"
        >删除</a-button>
        <a-button
          type="primary"
          size="small"
          @click="edit_task(text.id)"
          style="margin-left: 10px;">
          追加
        </a-button>
      </span>
      <p slot="expandedRowRender" slot-scope="text" style="margin: 0">
        <pre>{{ text.plugins.join("\n") }}</pre>
      </p>
    </a-table>
    <task-add ref="taskAdd" />
    <task-append ref="taskAppend" />
    <change-format ref="changeFormat" />
  </a-card>
</template>

<script>
import { getTaskList, deleteTask } from "@/api/task";
import TaskAdd from "./modules/TaskAdd";
import TaskAppend from "./modules/TaskAppend";
import ChangeFormat from "./modules/ChangeFormat";
import { changeTime } from "@/libs/utils";
import consts from "@/constants/plugin";
import task_consts from "@/constants/task";
import { Modal } from "ant-design-vue";
export default {
  components: {
    TaskAdd,
    TaskAppend,
    ChangeFormat,
  },
  data() {
    this.changeTime = changeTime;
    return {
      loading: false,
      data: [],
      globalSearch: "",
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          scopedSlots: { customRender: "id" },
          width: 80,
          sorter: true,
        },
        {
          title: "任务名称",
          dataIndex: "name",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
          width: 80,
        },
        {
          title: "任务类型",
          dataIndex: "type",
          ellipsis: true,
          scopedSlots: { customRender: "type" },
          width: 80,
          filters: [
            {
              text: "URL",
              value: 0
            },
            {
              text: "HOST",
              value: 1,
            },
            {
              text: "DOMAIN",
              value: 2
            }
          ],
          filterMultiple: false,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          ellipsis: true,
          scopedSlots: { customRender: "create_time" },
          width: 80,
          sorter: true,
        },
        {
          title: "任务状态",
          dataIndex: "status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
          width: 80,
          filters: [
            {
              text: "扫描中",
              value: 0
            },
            {
              text: "已完成",
              value: 1,
            }
          ],
          filterMultiple: false,
        },
        {
          title: "操作",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.fetch({...filterDic, ...orderingDic});
    },
    create_task() {
        this.$refs["taskAdd"].visible = true;
    },
    change_format() {
      this.$refs["changeFormat"].visible = true;
    },
    onSearch () {
      this.fetch();
    },
    delete_task(id) {
      Modal.confirm({
        title: "警告",
        content: "删除任务会删除任务的结果, 您确定要删除此任务吗?",
        onOk: () => {
            deleteTask(id)
            .then((res) => {
              if (res.status === 204) {
                this.$message.success("删除成功!");
                this.fetch();
              } else {
                this.$message.error("删除失败!");
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
    edit_task(id) {
      this.$refs["taskAppend"].init(id);
    },
    fetch(query={}) {
      this.loading = true;
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      query["page"] = this.pagination.current;
      getTaskList(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.loading = false;
    },
  },
  filters: {
    typePlugin (type) {
      return consts.pluginTypeList[type];
    },
    pluginStatusText (type) {
      return task_consts.taskStatusList[type].label;
    },
    pluginStatusColor (type) {
      return task_consts.taskStatusList[type].color;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>