import axios from "@/libs/api.request";

export const pluginList = (query) => {
  return axios.request({
    url: "/plugin/plugin/",
    method: "get",
    params: query,
  });
};

export const pluginDetail = (id) => {
  return axios.request({
    url: "/plugin/plugin/" + id + "/",
    method: "get",
  });
};

export const addPlugin = (reqdata) => {
  return axios.request({
    url: "/plugin/plugin/",
    method: "post",
    data: reqdata,
  });
};

export const changePlugin = (id, reqdata) => {
  return axios.request({
    url: "/plugin/plugin/" + id + "/",
    method: "put",
    data: reqdata,
  });
};

export const changePluginStatus = (id) => {
  return axios.request({
    url: "/plugin/plugin/update_status/",
    method: "get",
    params: { id: id },
  });
};

export const deletePlugin = (id) => {
  return axios.request({
    url: "/plugin/plugin/" + id + "/",
    method: "delete",
  });
};
