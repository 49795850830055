<template>
  <a-modal
    :visible="visible"
    title="任务追加"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        v-if="data.type === 0"
        ref="scheme"
        prop="scheme"
        label="协议"
      >
        <a-select v-model="form.scheme">
          <a-select-option value="http">http</a-select-option>
          <a-select-option value="https">https</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="data" prop="data" label="扫描数据">
        <a-textarea
          v-model="form.data"
          :autoSize="{ minRows: 8, maxRows: 12 }"
          :placeholder="scan_placeholder"
        ></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getTask, appendTask } from "@/api/task";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      scan_placeholder: "请选择任务类型",
      placeholder_options: {
        0: "",
        1: "目标格式(不设置端口则扫描所有端口):\n1.1.1.1\n1.1.1.1-1.1.1.255\n1.1.1.1:8000,8081-8100",
        2: "",
      },
      rules: {
        data: [{ required: true, message: "请填入扫描数据", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      data: {},
      form: {
        scheme: "",
        data: "",
      },
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      getTask(id)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    submit() {
      let query = {
        data: this.form.data,
        scheme: this.form.scheme,
        id: this.data.id,
      };
      appendTask(query)
        .then((res) => {
          if (res.data.status === 1) {
            this.$message.success(res.data.msg);
            this.visible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
