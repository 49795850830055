<template>
  <a-modal
    :visible="visible"
    title="任务创建"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="任务名称" ref="name" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item ref="type" prop="type" label="任务类型">
        <a-select
          v-model="form.type"
          placeholder="请输入任务类型"
          @change="change_task_type"
        >
          <a-select-option :value="0">URL</a-select-option>
          <a-select-option :value="1">HOST</a-select-option>
          <a-select-option :value="2">DOMAIN</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="plugin" prop="plugin" label="扫描插件">
        <a-select
          show-search
          mode="multiple"
          v-model="form.plugins"
          placeholder="请选择扫描插件"
          :filter-option="filterOption"
        >
          <a-select-option value="" disabled="">请选择</a-select-option>
          <a-select-option
            v-for="item in plugin_info"
            :key="item.id"
            :value="item.id"
            >{{ item.name }} [{{ item.id }}]</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.type === 0"
        ref="scheme"
        prop="scheme"
        label="协议"
      >
        <a-select v-model="form.scheme">
          <a-select-option value="http">http</a-select-option>
          <a-select-option value="https">https</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="data" prop="data" label="扫描数据">
        <a-textarea
          v-model="form.data"
          :autoSize="{ minRows: 8, maxRows: 12 }"
          :placeholder="scan_placeholder"
        ></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { pluginList } from "@/api/plugin";
import { addTask } from "@/api/task";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      scan_placeholder: "请选择任务类型",
      placeholder_options: {
        0: "",
        1: "目标格式(不设置端口则扫描所有端口):\n1.1.1.1\n1.1.1.1-1.1.1.255\n1.1.1.1:8000,8081-8100",
        2: "",
      },
      form: {
        name: "",
        type: null,
        plugins: [],
        data: "",
        scheme: "",
      },
      rules: {
        name: [{ required: true, message: "任务名不能为空", trigger: "blur" }],
        type: [{ required: true, message: "请选择任务类型", trigger: "blur" }],
        data: [{ required: true, message: "请填入扫描数据", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      plugin_info: [],
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          addTask(this.form)
            .then((res) => {
              if (res.data.status === 1) {
                this.$message.success(res.data.msg);
                this.visible = false;
                this.$refs["ruleForm"].resetFields();
                this.$parent.$parent.fetch();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs["ruleForm"].resetFields();
    },
    change_task_type(value) {
      this.scan_placeholder = this.placeholder_options[value];
      pluginList({ page: 1, page_size: 500, type: value })
        .then((res) => {
          this.plugin_info = res.data.results;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
