<template>
  <a-modal
    :visible="visible"
    title="格式转换"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="scheme" prop="scheme" label="协议">
        <a-select v-model="form.scheme">
          <a-select-option value="http">http</a-select-option>
          <a-select-option value="https">https</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="data" prop="data" label="扫描数据">
        <a-textarea
          v-model="form.data"
          :autoSize="{ minRows: 8, maxRows: 12 }"
          placeholder="请输入要转化的数据"
        ></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <div style="margin-top: 10px">
      <pre>{{ JSON.stringify(this.result, null, 4) }}</pre>
    </div>
  </a-modal>
</template>

<script>
import { changeFormat } from "@/api/task";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        data: "",
        scheme: "",
      },
      result: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {
        name: [
          { required: true, message: "要转化的数据不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          changeFormat(this.form)
            .then((res) => {
              if (res.data.status === 1) {
                this.$refs["ruleForm"].resetFields();
                this.result = res.data.msg;
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.$refs["ruleForm"].resetFields();
      this.result = "";
      this.visible = false;
    },
  },
};
</script>
